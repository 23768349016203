export const translations = {
  pt: {
    //Formulários
    name: "Nome",
    insertName: "Introduza o seu nome",
    insertNameError: "Insira um nome",
    email: "E-mail",
    insertEmail: "Introduza o seu e-mail",
    insertValidEmail: "Insira um e-mail válido",
    phone: "Telemóvel",
    insertPhone: "Introduza o seu telemóvel",
    insertValidPhone: "Insira um número de telemóvel válido",
    comments: "Comentários",
    kms: "Quilómetros",
    insertKms: "Inserir quilómetros",
    plate: "Matrícula",
    insertPlate: "Inserir matrícula",
    insertValidPlate: "Insira uma matrícula válida",
    insertValidValue: "Insira um valor válido",
    insertValidValueWNumber: "Insira um valor válido, apenas com números",
    acceptRGPDError:
      "* É necessário aceitar a política de privacidade e segurança e os termos e condições para prosseguir com a marcação",
    formSuccess:
      "Formulário submetido com sucesso. Irá ser contactado brevemente",
    formError: "Formulário não submetido. Por favor tente mais tarde",
    obs: "Por favor contactem-me entre as 12:00H e as 14:00H",
    //CTAs
    select: "Selecionar",
    send: "Enviar",
    continue: "Avançar",
    confirmar: "Confirmar",
    changePlate: "Alterar Matrícula",
    changeVehicle: "Alterar Viatura",
    seeMore: "Ver Mais",
    seeLess: "Ver Menos",
    finish: "Concluir",
    addServices: "Adicionar Serviços",
    //Titles
    identifyVehicle: "Identifique o seu Veículo",
    selectVehicle: "Selecione a Viatura para Marcação",
    insertContactDetails: "Insira os seus Dados de Contacto",
    selectDealer: "Selecione a sua Oficina",
    mobilityServices: "Serviços de Mobilidade",
    plateNotFound: "Matrícula Não Encontrada",
    resume: "Resumo",
    pickDateHour: "Escolha a Data e Hora",
    selectServices: "Selecione os Serviços que Pretende",
    washServices: "Serviços de Limpeza",
    //Labels
    campaign: "Campanha",
    dealer: "Oficina",
    mobility: "Mobilidade",
    wash: "Limpeza",
    dateHour: "Data/Hora",
    free: "Grátis",
    onRequest: "Sob consulta",
    selectOtherHour: "Selecione outro horário",
    totalValueServices: "Valor total dos serviços",
    vehicle: "Viatura",
    otherVehicle: "Outra Viatura",
    currentLocation: "Localização Atual",
    list: "Lista",
    map: "Mapa",
    services: "Serviços",
    total: "Total",
    seeResume: "Ver Resumo",
    brand: "Marca",
    selectBrand: "Selecione uma marca",
    model: "Modelo",
    horsePower: "Cavalos",
    selectModel: "Selecione um modelo",
    brandModel: "Marca e Modelo",
    version: "Versão",
    selectVersion: "Selecione uma versão",
    body: "Carroçaria",
    bodyType: "Tipo de Carroçaria",
    selectBody: "Selecione a carroçaria",
    fuel: "Combustível",
    selectFuel: "Selecione o combustível",
    gearType: "Tipo de Caixa",
    plateDate: "Data de Matrícula",
    doorsNumber: "Número de Portas",
    cc: "Cilindrada",
    personalData: "Dados Pessoais",
    init: "Início",
    dates: "Dados",
    serviceDate: "Data do Serviço",
    serviceHour: "Hora do Serviço",
    dealerName: "Nome da Oficina",
    //Messages
    noWashServicesAvailable: "Não há serviços de lavagem disponíveis",
    noMobilityServicesAvailable: "Não há serviços de mobilidade disponíveis",
    fieldsToFoundCar: "Por favor, preencha os campos e encontre o seu carro.",
    maintencanceDisclaimer:
      "Os preços apresentados consideram o plano de manutenção previsto para a viatura e quilómetros indicados. A oficina reserva o direito de ajustar o preço, tendo em conta o plano efetivamente aplicável à viatura, que pode variar em função do seu histórico de manutenção, estado de utilização e dados fornecidos pelos sistemas de informação do fabricante. Estes preços não se aplicam a viaturas que usufruem de contratos de manutenção.",
    notIncludedInPrice: "não incluído no preço",
    notAvailableToContinue:
      "De momento, não é possível prosseguir com a marcação do serviço de oficina.",
    tryLater:
      "Por favor, tente mais tarde. Em alternativa, deixe uma mensagem e entraremos em contacto consigo.",
    getEmailConfirmation:
      "De seguida, receberá uma mensagem de confirmação na sua caixa de email.",
    gelocationNotSupported: "Geolocation não é suportada neste browser.",
    locationBlocked: "Bloqueou a permissão para a sua localização.",
    locationUnavailable: "Informação de localização indisponível.",
    locationFailed: "Pedido de localização falhou.",
    unknownError: "Erro desconhecido.",
    notPossibleToConfirme:
      "De momento, não é possível confirmar a sua marcação.",
    dataSaved:
      "Não se preocupe, guardámos todos os dados que nos indicou até ao momento.",
    proceedWithSchedule:
      "Deixe-nos os seus contactos para darmos seguimento à sua marcação.",
    servicesWithConfirmation:
      "Serviços que necessitam de confirmação. Ao selecionar estes serviços será contactado por um operador.",
    noServiceSelected: "Não selecionou nenhum serviço para o seu ",
    noWashServiceSelected: "Não selecionou nenhum serviço para a sua ",
    wantToProceed:
      "Tem a certeza que pretende avançar para os serviços de mobilidade e de limpeza?",
    wantToProceedWash:
      "Por favor selecionar um serviço de valor superior para poder avançar.",
    //Days
    weekdays: ["SEG", "TER", "QUA", "QUI", "SEX", "SÁB", "DOM"],
    //Caetano GO
    caetanoGoPerks: "A sua conta Caetano Go vai-lhe permitir:",
    caetanoGoPerks1: "Registar e certificar a sua viatura",
    caetanoGoPerks2: "Ter acesso ao histórico de manutenção",
    caetanoGoPerks3: "Desbloquear vantagens e descontos exclusivos",
    caetanoGoCheck: "Tem conta Caetano Go?",
    caetanoGoNotCheck: "Não tem conta Caetano Go?",
    login: "Inicie Sessão",
    register: "Registe-se",
    myVehicles: "Minhas Viaturas",
    caetanoGoOr: "ou",
    //URLs
    vehicleWithService: "servicos-agendados",
    carSelectionLink: "selecao-viatura",
    carVersionSelect: "selecao-versao",
    plateNotFoundLink: "matricula-nao-encontrada",
    carIdentificationLink: "identificacao-viatura",
    carConfirmationLink: "confirmacao-viatura",
    dealerSelectionLink: "oficina",
    servicesLink: "servicos",
    mobilityServicesLink: "mobilidade",
    washServicesLink: "lavagem",
    scheduleLink: "data-hora",
    contactInfoLink: "dados-contacto",
    resumeLink: "resumo",
    confirmationLink: "concluido",
    errorFormLink: "erro",
    Login: "login",
    HomePage: "Homepage",
    //Confirmação
    acknowledgment: "Obrigado pela sua marcação",
    goTo: "Ir para",
    knowMore: "Saber mais",
    //ContactInfo
    dataAuthorization:
      "Os dados aqui solicitados são os necessários para a Caetano Retail poder dar seguimento ao teu processo de marcação online.",
    createAccount:
      "Criar conta Caetano Go, com os dados inseridos, para acesso a Área Reservada.",
    acceptRGPD:
      "Li, compreendi e aceito a %%Política de Privacidade e Segurança%% / %%Termos e Condições%% *",
    consentCenter:
      "Gostaria de receber comunicações de marketing, nomeadamente de promoções, eventos e novos produtos da Caetano Retail, Salvador Caetano Auto, SGPS, S.A e suas participadas, seja através de e-mail, telefone ou SMS.",
  },
  es: {
    //Formulários
    name: "Nombre",
    insertName: "Introduce tu nombre",
    insertNameError: "Inserta un nombre",
    email: "Correo electrónico",
    insertEmail: "Introduce tu correo electrónico",
    insertValidEmail: "Introduce un correo electrónico válido",
    phone: "Teléfono móvil",
    insertPhone: "Teclea tu teléfono móvil",
    insertValidPhone: "Teclea un número válido de teléfono móvil",
    comments: "Comentario",
    kms: "Kilómetros",
    insertKms: "Kilómetros que tiene tu vehículo",
    plate: "Matrícula",
    insertPlate: "Esctribe la matrícula",
    insertValidPlate: "Inserta una matrícula válida",
    insertValidValue: "Escribe un valor válido",
    insertValidValueWNumber: "Escribe un valor válido, solo con números",
    acceptRGPDError:
      "* É necessário aceitar a política de privacidade e segurança e os termos e condições para prosseguir com a marcação",
    formSuccess: "Formulario enviado correctamente. Será contactado en breve",
    formError: "Formulario no enviado. Por favor, inténtalo de nuevo más tarde",
    wantToProceedWash: "Seleccione un servicio de mayor valor para continuar.",
    obs: "Por favor contáctame entre las 12:00 y las 14:00",
    //CTAs
    select: "Seleccionar",
    send: "Enviar",
    continue: "Siguiente",
    confirmar: "Confirmar",
    changePlate: "Cambiar matrícula",
    changeVehicle: "Cambiar vehículo",
    seeMore: "Ver más",
    seeLess: "Ver menos",
    finish: "Finalizar",
    addServices: "Agregar servicios",
    //Titl ESes
    identifyVehicle: "Identifica tu vehículo",
    selectVehicle: "Selecciona el vehículo para la cita",
    insertContactDetails: "Introduce tus datos de contacto",
    selectDealer: "Selecciona tu taller",
    mobilityServices: "Servicios de movilidad",
    plateNotFound: "Matrícula no encontrada",
    resume: "Resumen",
    pickDateHour: "Elije la fecha y hora",
    selectServices: "Selecciona los servicios que deseas",
    washServices: "Servicios de limpieza",
    //Labels
    campaign: "Campaña",
    dealer: "Taller",
    mobility: "Movilidad",
    wash: "Limpieza",
    dateHour: "Fecha/hora",
    free: "Gratis",
    onRequest: "Bajo pedido",
    selectOtherHour: "Selecciona otro momento",
    totalValueServices: "Valor total de los servicios",
    vehicle: "Vehículo",
    otherVehicle: "Otro vehículo",
    currentLocation: "Ubicación actual",
    list: "Listado",
    map: "Mapa",
    services: "Servicios",
    total: "Total",
    seeResume: "Ver resumen",
    brand: "Marca",
    selectBrand: "Selecciona una marca",
    model: "Modelo",
    horsePower: "Caballos",
    selectModel: "Selecciona un modelo",
    brandModel: "Marca y Modelo",
    version: "Versión",
    selectVersion: "Selecciona una versión",
    body: "Carrocería",
    bodyType: "Tipo de carrocería",
    selectBody: "Selecciona la carrocería",
    fuel: "Combustible",
    selectFuel: "Selecciona el combustible",
    gearType: "Tipo de transmisión",
    plateDate: "Fecha de matrícula",
    doorsNumber: "Número de puertas",
    cc: "Cilindros",
    personalData: "Datos personales",
    init: "Comenzar",
    dates: "Datos",
    serviceDate: "Fecha do Serviço",
    serviceHour: "Hora de servicio",
    dealerName: "Nombre del comerciante",
    //Messages
    noWashServicesAvailable: "No hay servicios de lavado disponibles.",
    noMobilityServicesAvailable: "No hay servicios de movilidad disponibles..",
    fieldsToFoundCar: "Por favor, completa los campos y selecciona tu vehículo",
    maintencanceDisclaimer:
      "Los precios mostrados consideran el plan de mantenimiento previsto para el vehículo y los kilómetros indicados. El taller se reserva el derecho de ajustar el precio, teniendo en cuenta el plan efectivamente aplicable al vehículo, que podrá variar en función de su historial de mantenimiento, estado de uso y datos proporcionados por los sistemas de información del fabricante. Estos precios no se aplican a los vehículos que se benefician de contratos de mantenimiento.",
    notIncludedInPrice: "no incluido en el precio",
    notAvailableToContinue:
      "En estos momentos no es posible una  solicitud de cita de taller.",
    tryLater:
      "Por favor, inténtalo de nuevo más tarde. Si nos dejas un mensaje nos pondremos brevemente en contacto contigo.",
    getEmailConfirmation:
      "En breves momentos recibirás un mensaje de confirmación a tu correo electrónico (por favor, revisa la bandeja de no deseado en caso de no encontrarlo)",
    gelocationNotSupported:
      "La geolocalización no es compatible con este navegador.",
    locationBlocked: "Permiso bloqueado para tu ubicación.",
    locationUnavailable: "Información de ubicación no disponible.",
    locationFailed: "La solicitud de ubicación falló.",
    unknownError: "Error desconocido.",
    notPossibleToConfirme: "Por el momento no es posible confirmar tu cita.",
    dataSaved:
      "No te preocupes, hemos guardado todos los datos que nos has indicado hasta ahora.",
    proceedWithSchedule:
      "Por favor, facilítanos tus contactos para seguir continuar con la cita.",
    servicesWithConfirmation:
      "Servicios que necesitan confirmación. Al seleccionar estos servicios, serás contactado por un operador.",
    noServiceSelected: "No has seleccionado ningún servicio.",
    wantToProceed:
      "¿Seguro que quieres avanzar a los servicios de movilidad y limpieza?",
    noWashServiceSelected: "No has seleccionado ningún servicio para tu ",
    //Days
    weekdays: ["LUN", "MAR", "MIÉ", "JUE", "VIE", "SÁB", "DOM"],
    //Caetano GO
    caetanoGoPerks: "Con tu cuenta de Caetano Go podrás:",
    caetanoGoPerks1: "Regístrate y certifica tu coche",
    caetanoGoPerks2: "Tener acceso al historial de mantenimiento",
    caetanoGoPerks3: "Desbloquear ventajas y descuentos exclusivos",
    caetanoGoCheck: "¿Tienes cuenta de Caetano Go?",
    caetanoGoNotCheck: "¿No tienes la cuenta de Caetano Go?",
    login: "Sesión de inicio",
    register: "Registro",
    myVehicles: "Mis vehículos",
    caetanoGoOr: "o",
    //URLs
    carSelectionLink: "seleccion-vehiculo",
    carVersionSelect: "seleccionar-vehiculo",
    plateNotFoundLink: "matricula-no-encontrada",
    carIdentificationLink: "identificacion-vehiculo",
    carConfirmationLink: "confirmacion-vehiculo",
    dealerSelectionLink: "taller",
    servicesLink: "servicios",
    mobilityServicesLink: "movilidad",
    washServicesLink: "lavado",
    scheduleLink: "fecha-hora",
    contactInfoLink: "datos-contacto",
    resumeLink: "resumen",
    confirmationLink: "finalizado",
    errorFormLink: "error",
    //Confirmação
    acknowledgment: "Gracias por su reserva",
    goTo: "Ir para",
    knowMore: "Saber mas",
    //ContactInfo
    dataAuthorization:
      "Os dados aqui solicitados são os necessários para a Caetano Retail poder dar seguimento ao teu processo de marcação online.",
    createAccount:
      "Criar conta Caetano Go, com os dados inseridos, para acesso a Área Reservada.",
    acceptRGPD:
      "Li, compreendi e aceito a %%Política de Privacidade e Segurança%% / %%Termos e Condições%% *",
    consentCenter:
      "Gostaria de receber comunicações de marketing, nomeadamente de promoções, eventos e novos produtos da Caetano Retail, Salvador Caetano Auto, SGPS, S.A e suas participadas, seja através de e-mail, telefone ou SMS.",
  },
};
