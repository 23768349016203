import { country } from "@/services/country";
import axios from "axios";
import * as endpoints from "./endpoints";

//AC-80-TO

const timeout = 30000;

export async function loadCaetanoGoInfo(state) {
  const headers = {
    IDCaetanogo: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    const cgUser = axios.get(endpoints.url.getCGUser, {
      headers: { ...headers },
      timeout: timeout,
    });

    const cgVehicles = axios.get(endpoints.url.getCGVehicles, {
      headers: { ...headers },
      timeout: timeout,
    });
    const response = await axios.all([cgUser, cgVehicles]);

    return response;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadWPInfo() {
  try {
    const response = await axios.get(
      endpoints.url.getGeneralInfo + "?country=" + country,
      {
        headers: { ...endpoints.WPgeneralHeaders },
        timeout: timeout,
      }
    );

    return response;
  } catch (error) {
    console.warn(error);
  }
}

export async function extractImageUrl(obj) {
  if (obj && obj.backgroundImage && obj.backgroundImage.url) {
    return obj.backgroundImage.url;
  }
  return null;
}

export async function loadCaetanoGoLink(state) {
  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    var currentSite = window.location.origin;
    if(state.firstPath != "") {
      currentSite += '/' + state.firstPath;
      currentSite = currentSite.toLowerCase();
    }
    const response = await axios.get(
      endpoints.url.getCGLink +
        endpoints.returnType +
        "?url=" +
        currentSite +
        "&session=" +
        state.randomKey,
      {
        headers: headers,
        timeout: timeout,
      }
    );

    return response;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadAll(object, state) {
  let url;

  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  switch (object) {
    case "brand":
      url = endpoints.url.getCarNotFound;
      break;
    case "model":
      url = endpoints.url.getCarNotFound + "?make=" + state.carNF.brand;
      break;
    case "body":
      url =
        endpoints.url.getCarNotFound +
        "?make=" +
        state.carNF.brand +
        "&model=" +
        state.carNF.model;
      break;
    case "fuel":
      url =
        endpoints.url.getCarNotFound +
        "?make=" +
        state.carNF.brand +
        "&model=" +
        state.carNF.model +
        "&body=" +
        state.carNF.body;
      break;
    case "version":
      url =
        endpoints.url.getCarNotFound +
        "?make=" +
        state.carNF.brand +
        "&model=" +
        state.carNF.model +
        "&body=" +
        state.carNF.body +
        "&fuel=" +
        state.carNF.fuel;
      break;
    case "token":
      url =
        endpoints.url.getCarNotFound +
        "?make=" +
        state.carNF.brand +
        "&model=" +
        state.carNF.model +
        "&body=" +
        state.carNF.body +
        "&fuel=" +
        state.carNF.fuel +
        "&version=" +
        state.carNF.version;
      break;
    default:
      url = endpoints.url.getCarNotFound;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        ...headers,
      },
      timeout: timeout,
    });

    return response.data;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadData(value, url, state) {
  const campaignsQuery = state.idCampaigns
    ? "?idCampaigns=" + state.idCampaigns
    : "";

  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    const response = await axios.get(
      endpoints.url[url] + value + endpoints.returnType + campaignsQuery,
      {
        headers: {
          ...headers,
        },
        timeout: timeout,
      }
    );

    return response.data;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadServices(dealer, state, idCampaigns) {
  const campaignsQuery = idCampaigns ? "&idCampaigns=" + idCampaigns : "";
  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    const response = await axios.get(
      endpoints.url.getServices +
        dealer +
        endpoints.returnType +
        "?plate=" +
        state.carPlate +
        "&maintenance=" +
        state.carComboSelected.comboId +
        "&km=" +
        state.carKms +
        campaignsQuery,
      {
        headers: {
          ...headers,
        },
        timeout: timeout,
      }
    );

    return response;
  } catch (error) {
    console.warn(error);
  }
}

//aqui novo serviço  evandro
export async function loadCorrectToken(state) {
  const path = `/vehicleID/${state.selectedCarKtype}/plate/${state.carPlate}`;
  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    const response = await axios.get(
      endpoints.url.getCorrectToken + path + endpoints.returnType,
      {
        headers: { ...headers },
        timeout: timeout,
      }
    );
    if (response) {
      console.log(state.selectedCarKtypeInfo);
    } else {
      console.log("Not response.");
    }
    return response;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadCampaign(state, idCampaigns) {
  const campaignsQuery = idCampaigns ? "?idCampaigns=" + idCampaigns : "";

  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    const response = await axios.get(
      endpoints.url.getCampaignInfo + endpoints.returnType + campaignsQuery,
      {
        headers: {
          ...headers,
        },
        timeout: timeout,
      }
    );
    return response;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadCarInfo(state, plate) {
  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    const response = await axios.get(
      endpoints.url.getCarByPlate + plate + endpoints.returnType,
      {
        headers: {
          ...headers,
        },
        timeout: timeout,
      }
    );

    return response;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadCarCombo(state, plate) {
  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };
  try {
    const response = await axios.get(
      endpoints.url.getCombosByPlate + plate + endpoints.returnType,
      {
        headers: {
          ...headers,
        },
        timeout: timeout,
      }
    );

    return response;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadCarInfoByPlate(state) {
  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    const response = await axios.get(
      endpoints.url.getCombosByToken +
        endpoints.returnType +
        "?Token=" +
        state.carNF.token +
        "&Plate=" +
        state.carNF.plate,
      {
        headers: {
          ...headers,
        },
        timeout: timeout,
      }
    );

    return response.data;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadMobilityServices(state, brand, idDealer) {
  const campaignsQuery = state.idCampaigns
    ? "?idCampaigns=" + state.idCampaigns
    : "";

  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  try {
    const response = await axios.get(
      endpoints.url.getMobilityServices1 +
        brand +
        endpoints.url.getMobilityServices2 +
        idDealer +
        endpoints.returnType +
        campaignsQuery,
      {
        headers: {
          ...headers,
        },
        timeout: timeout,
      }
    );

    return response.data;
  } catch (error) {
    console.warn(error);
  }
}

export async function loadAvailability(state) {
  let serviceIds = "?workIds=";

  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };

  const allServicesIds = [
    ...state.workIds,
    ...state.maintenanceList,
    ...state.complementaryList,
  ];

  allServicesIds.forEach((el) => {
    serviceIds += el + ",";
  });

  serviceIds = serviceIds.substring(0, serviceIds.length - 1);

  serviceIds += "&lstWash=";

  state.washServiceIDSelected.forEach((el) => {
    serviceIds += el + ",";
  });

  serviceIds = serviceIds.substring(0, serviceIds.length - 1);

  try {
    const response = await axios.get(
      endpoints.url.getAvailability1 +
        state.carPlate +
        endpoints.url.getAvailability2 +
        state.carKms +
        endpoints.url.getAvailability3 +
        state.dealerSelected.dealerID +
        endpoints.url.getAvailability4 +
        state.carComboSelected.comboId +
        endpoints.url.getAvailability5 +
        state.mobilityServiceIDSelected +
        endpoints.returnType +
        serviceIds,
      {
        headers: {
          ...headers,
        },
        timeout: timeout,
      }
    );

    return response.data;
  } catch (error) {
    console.warn(error);
  }
}

export async function searchUserCaetanoGo(state) {
  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
    email: state.contactInfo.email,
  };

  try {
    const response = await axios.get(endpoints.url.searchUserCaetanoGo, {
      headers: {
        ...headers,
      },
      timeout: timeout,
    });

    return response.data;
  } catch (error) {
    console.warn(error);
  }
}

export async function makeAppointment(state) {
  const headers = {
    sessionID: state.randomKey,
    Authorization: state.sessionToken,
    country: country,
  };
  let campaignsUsedString = "";
  if (state.campaignsUsed.length > 0) {
    campaignsUsedString = "?idCampaigns=";
    state.campaignsUsed.forEach((el) => {
      campaignsUsedString += el + ",";
    });
  }
  const idCita = state.markingServiceSelected ? state.markingServiceSelected.markingServiceId : null;
  if (idCita) {
    campaignsUsedString += campaignsUsedString ? "&idCita=" + idCita : "?idCita=" + idCita;
  }
  //remove last comma
  if (campaignsUsedString.endsWith(',')) {
    campaignsUsedString = campaignsUsedString.substring(0, campaignsUsedString.length - 1);
  }
 /*  campaignsUsedString = campaignsUsedString.substring(
    0,
    campaignsUsedString.length - 1
  ); */
  const body = {
    name: state.contactInfo.name,
    email: state.contactInfo.email,
    userEmail: state.userInfo && state.userInfo.mail ? state.userInfo.mail : "",
    phone: state.contactInfo.phone,
    site: state.site ? state.site : "",
    createAccount: state.contactInfo.createAccount,
    consentCenter: state.contactInfo.consentCenter,
    idNewsletter: state.oid_newsletter ? state.oid_newsletter : "",
    model: state.carByPlate.model ? state.carByPlate.model : state.carNF.model,
    plateNr: state.carPlate ? state.carPlate : state.carNF.plate,
    km: state.carKms ? Number(state.carKms) : Number(state.carNF.kms),
    idBrand: state.carByPlate.idbrand ? Number(state.carByPlate.idbrand) : -1,
    brand: state.carByPlate.brand ? state.carByPlate.brand : state.carNF.brand,
    idMsoBrand: state.carByPlate.idbrand
      ? Number(state.carByPlate.idbrand)
      : -1,
    idInstallation: state.dealerSelected.dealerID
      ? Number(state.dealerSelected.dealerID)
      : -1,
    serviceDate:
      state.availabilitySelected.day != ""
        ? state.availabilitySelected.day
        : null,
    serviceHour:
      state.availabilitySelected.hour != ""
        ? state.availabilitySelected.hour
        : null,
    obs: state.obs,
    workIds: state.workIds,
    maintenanceList: state.maintenanceList,
    complementaryList: state.complementaryList,
    idMobility:
      state.mobilityServiceIDSelected != ""
        ? state.mobilityServiceIDSelected[0]
        : -1,
    maintenanceVarId:
      state.carComboSelected.length > 0
        ? Number(state.carComboSelected.comboId)
        : -1,
    simulatedInfo: false,
    campaingOrSimpleRevision: "Revisão",
    campaignTimeDMS: 0.0,
    url: state.url ?? "",
    utms: {
      utm_source: state.utm_source ?? "",
      utm_medium: state.utm_medium ?? "",
      utm_campaign: state.utm_campaign ?? "",
      utm_term: state.utm_term ?? "",
      utm_content: state.utm_content ?? "",
    },
    idWash: state.washServiceIDSelected,
  };

  const plate = state.carPlate ? state.carPlate : "NA";

  try {
    const response = await axios.post(
      endpoints.url.scheduleService +
        plate +
        endpoints.returnType +
        campaignsUsedString,
      body,
      {
        headers: {
          ...headers,
        },
      }
    );
    return response;
  } catch (error) {
    console.warn(error);
  }
}
